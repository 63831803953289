import React from 'react'
import Hero from '../components/Hero'
function Home() {
  return (
    <div>
        <Hero/>
        <section>
            
        </section>
    </div>
  )
}

export default Home